import {get} from "@/services/api";

export default {
  name: "ListFaqs",
  data: () => ({
    arrayData: [],
    faqSelected: null,
    showOverlay: false,
  }),
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.arrayData = []
      const {data} = await get("news/get-faqs-client", null, false)
      this.arrayData = data.map(el => ({...el, visible: false})).filter(x => x.faqs.length)
    },

    async selectedLine(item){
      this.showOverlay = true
      this.faqSelected = null
      item.visible = !item.visible
      this.$nextTick(() => {
        this.faqSelected = item
      })
      setTimeout(()=>{
       this.showOverlay = false
     },3000)
    }

  }
}
